@import url('https://fonts.googleapis.com/css2?family=Poppins:wght@100;200;300;400;500;600;700&display=swap');
@import url('https://fonts.googleapis.com/css2?family=Tangerine:wght@400;700&display=swap');

@tailwind base;
@tailwind components;
@tailwind utilities;

.btn {
	@apply shadow-md py-3 px-6 rounded-md transition duration-300;
}

.btn-purple {
	@apply bg-bookmark-purple text-white;
}

.btn-blue {
	@apply bg-bookmark-blue text-white;
}

.btn-white {
	@apply bg-bookmark-white;
}

.btn-papaya {
	@apply bg-bookmark-papaya;
}

.bg-squares {
	background-image: url("../img/cuadros_3.jpg");
	background-size: cover;
}